import React from "react";

function Search({ setItems, locale }) {
  const [seo, setSeo] = React.useState("");
  const [error, setError] = React.useState("");

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      search();
    }

    if (e.key === "Escape") {
      clearData();
    }
  };

  const search = async (e) => {
    setError("");
    setItems([]);

    if (!seo) {
      setError("Please enter an restaurants SEO name.");
      return;
    }

    fetch(`${locale.endpoint}/${seo}_${locale.countryCode}_items.json`)
      .then((Response) => Response.json())
      .then((data) => setItems(data.Items))
      .catch((e) => {
        setError(
          "There was a problem retrieving that menu. Please check the SEO is correct, and you're using the right market and try again."
        );
        return;
      });
  };

  const clearData = () => {
    setSeo("");
    setItems([]);
    setError("");
  };

  return (
    <div className="max-w-5xl m-auto bg-slate-100 rounded-b-md py-4 px-2 shadow-md">
      {error && (
        <div className="bg-red-500 -mx-2 -mt-4 py-2 px-4 font-medium text-sm text-white shadow-md">
          Error: {error}
        </div>
      )}
      <h1 className="text-xl font-medium text-slate-700 my-2">
        Menu Finder - {locale.name}
      </h1>
      <h2 className="text-slate-600 mb-2">Restaurant SEO:</h2>
      <input
        className="mb-2 text-slate-700"
        type="text"
        value={seo}
        onChange={(e) => setSeo(e.target.value.toLowerCase())}
        onKeyDown={handleKeyDown}
      />
      <div>
        <button className="bg-orange-500" onClick={search}>
          Search
        </button>
        {seo && (
          <button className="bg-red-500" onClick={clearData}>
            Clear
          </button>
        )}
      </div>
    </div>
  );
}

export default Search;

// Developed with ❤ by Reece Devonport
