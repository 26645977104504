import React, { useState } from "react";
import Item from "./Item";

function ItemList({ items, locale }) {
  const [search, setSearch] = useState("");

  return (
    <div className="max-w-5xl mt-2 m-auto bg-slate-100 rounded-md py-4 px-2 shadow-md">
      <h2 className="text-slate-600 mb-2">Search items:</h2>
      <input
        className="mb-5 text-slate-700"
        type="text"
        placeholder="Example: Chicken Wings"
        onChange={(e) => {
          setSearch(e.target.value);
        }}
      />
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
        {(search
          ? items.filter((item) =>
              item.Name.toLowerCase().includes(search.toLowerCase())
            )
          : items
        ).map((item) =>
          item.Variations[0].BasePrice === 0 ? null : (
            <Item key={item.Id} item={item} locale={locale} />
          )
        )}
      </div>
    </div>
  );
}

export default ItemList;

// Developed with ❤ by Reece Devonport
