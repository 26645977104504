import "./App.css";
import { useEffect, useState } from "react";
import Search from "./components/Search";
import ItemList from "./components/ItemList";
import locales from "./locale.json";

function App() {
  const [items, setItems] = useState([]);
  const [locale, setLocale] = useState(locales.unitedkingdom);
  const [modal, setModal] = useState(false);

  const toggleModal = () => {
    setModal(!modal);
  };

  return (
    <div className="App p-5">
      <div className="bg-orange-500 max-w-5xl mx-auto p-2 underline rounded-t-md shadow-md relative">
        <img
          src={`../img/${locale.countryCode}.svg`}
          alt={locale.name}
          className="flag-icon absolute p-0.7 shadow-md"
          onClick={toggleModal}
        />
        {modal && (
          <div className="bg-white rounded-t-full rounded-b-full absolute top-11 shadow-md">
            <img
              src={`../img/uk.svg`}
              className="flag-icon p-0.1 mb-1"
              onClick={() => {
                setLocale(locales.unitedkingdom);
                toggleModal();
              }}
            />
            <img
              src={`../img/ie.svg`}
              className="flag-icon p-0.1 mb-1"
              onClick={() => {
                setLocale(locales.ireland);
                toggleModal();
              }}
            />
            <img
              src={`../img/es.svg`}
              className="flag-icon p-0.1 mb-1"
              onClick={() => {
                setLocale(locales.spain);
                toggleModal();
              }}
            />
            <img
              src={`../img/it.svg`}
              className="flag-icon p-0.1 mb-1"
              onClick={() => {
                setLocale(locales.italy);
                toggleModal();
              }}
            />
            <img
              src={`../img/au.svg`}
              className="flag-icon p-0.1"
              onClick={() => {
                setLocale(locales.australia);
                toggleModal();
              }}
            />
          </div>
        )}
        <img
          src={`../img/${locale.logo}.svg`}
          alt="JET Logo"
          className="w-16 md:w-20 lg:w-24 mx-auto"
        />
      </div>

      <Search setItems={setItems} locale={locale} />

      {items && items.length > 0 && <ItemList items={items} locale={locale} />}
      <div className="my-5 font-medium text-sm text-slate-600">
        Made with 🧡 by Reece Devonport
      </div>
    </div>
  );
}

export default App;

// Developed with ❤ by Reece Devonport
